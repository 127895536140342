@font-face {
    font-family: 'Scheherazade';
    font-style: normal;
    font-weight: 400;
    src: url(font/Scheherazade-R.woff) format('woff');
}

body {background-color:#f9fafc;}
#footer {
    position:fixed;
    left:0px;
    bottom:0px;
    height:110px;
    width:100%;
    background:#999;
    z-index: 100;
}
#footer2 {
    position:fixed;
    left:0px;
    bottom:0px;
    height:30px;
    width:100%;
    background:#999;
    z-index: 100;
}

#fitur{
    padding-left:0%;
    margin-top:8%;
    padding-bottom:1%;
    background-color:#7c5c4f;
}
footer {
    background-color:#3e2e28;
    color: #d3c8bb;
    padding: 40px 0;
}
footer h4{
    color: #fafafa;
}
footer a, footer a:hover{
    color: #ffca36;
}
footer .copyright {
    font-size: .9em;
    margin-top: 20px;
}

#searchbar{
    background-color:#d2d0c3;
    padding-top:80px;
    padding-bottom:3%;
}
#popular{
    margin-top:4%;
    padding-left:0%;
    padding-right:0%;
}
#result{
    padding-left:0%;
    padding-right:0%;
    padding-bottom: 10%;
}
#popular-mobile{
    margin-top:5%;
    padding-left:0%;
    padding-right:0%;
}
#review{
    background-color:#fabb85;
    border-color:#564941;
    border:1px solid;
    padding-right:0%;
    border-radius:8px;
    padding-left:0%;
    margin-top:2%;
}
#user{
    background-color:#fabb85;
    padding-left:0%;
    padding-top:2%;
}
#konten{
    background-color:white;
    border-top-right-radius:3px;
    border-bottom-right-radius:8px;
    border-top-left:0px;
}
#artikel{
    background-color:white;
    border-color:#564941;
    border:1px solid;
    padding-right:2%;
    border-radius:8px;

}
#konten-artikel{
    border:solid 1px;
    border-color:#7c5c4f;
    border-radius:1px;
}
#line{
    border:solid 1px;
    border-color:#7c5c4f;
    border-radius:1px;
    margin-top:3%;
}
#comment{
    background-color:#ededed;
    margin-left:1%;
    border-radius:2px;
    margin-top:2%;
}
#surat{
    padding-top:3%;
    padding-left:6%;
    margin-bottom: 20%;
}
.arabic {
    direction: rtl;
    font-family: "Amiri","Scheherazade", "Traditional Arabic";
    font-size: 1.7em;
    letter-spacing: 1px;
    line-height: 2em;
}
.panel-choco{
    background-color: #593B30 !important;
}
.panel-tafsir{
    background-color: #FFF8E8 !important;
}
.choco-border{
    border-color: #593B30;
}
.login-button, .fb:hover{
    background-color: #3b5998 !important;
}
.fb{
    color: #fff !important;
    text-align: left;
}
ul.tabs{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
ul.tabs li{
    background: none;
    color: #fff;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}

.tab-content{
    display: none;
    margin-top: 10px;
}

.tab-content.current{
    display: inherit;
}
li.current, .tab-link:active, a.acurrent{
    background-color: #F48121 !important;
}
.choco-text, .ayh_numbers{
    color: #3E2E28 !important;
}
.choco-text:hover{
    color: #593B30;
}
.search-choco{
    padding: 60px 0;
}
.title-topic{
    background-color: #593b30;
    color: white;
    padding: 10px;
    border-radius: 3px;
}
p.translation, .tafsir-deskripsi{
    text-align: justify;
    font-size: 1.0em;
    font-weight: normal;
}
div.btn-group a{
    font-size: 1.1em;
    margin: 5px;
    padding: 5px 20px;
}
.juz {
    margin-bottom: 50px;
}
.fill{
    margin: 70px 0px;
}
.home{
    padding-top: 7%;
}
.fitur{
    color: #fff;
    padding-left: 0%;
    margin-top: 10%;
    padding-bottom: 1%;
}
.fitur .title {font-size: 1.5em !important;}
.header, .fitur, .foot{
    background-color: #593B30;
}
.header{
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
}

.dropdown-menu, .navbar-default, .navbar-default .navbar-collapse{
    border:none !important;
}
.foot-middle{
    background-color: #3e2e28;
    padding-bottom: 1%;
    color: #fff;
}
.foot-middle div{
    padding-left: 2%;
    padding-top: 1%;
}
.foot-middle div p a {
    color: #fff;
}
.social img{
    display: inline-block;
    position: relative;
}
.foot{
    margin-top: 0%;
    padding-top: 0.5%;
    color: #fff;
}
.prev, .next, .title-header, .title-header:hover, #act{
    color: #fff;
}
h1.title-header{margin-top: 10px;font-size: 1.5em;}
h2.title-header{margin-top: 10px;font-size: 1.5em;}
.title-header a {color: #fff}

.prev:hover, .next:hover{
    color: #F48121;
}
.tag-content{
    background-color: #FFF;
    border-color: #000;
    color: #000;
}
#act{
    background: none !important;
    text-align: left;
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a{
    color: #fff;
}

.navbar-nav > li.paypal{
    padding: 15px 0 0 15px;
}
@media (max-width: 768px) {
    .navbar-nav {
        text-align: center;
    }
    .navbar-nav > li{
        display: inline-block;
    }
    .navbar-nav > li > a {
        padding: 5px;
    }
    .navbar-nav > li.paypal{
        padding: 0;
    }
}

.dropdown-menu{
    background-color: #3E2E28 !important;
}
.panel-medina{
    margin: 0px auto;
    padding: 30px;
    direction: rtl;
    width: 100%;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #593B30 !important;
}
#logo{
    padding: 9px 15px;
}
.panel-content{
    background-color: #FFF8E8;
}
.btn-tab {
    color: #FFF !important;
    background-color: #5A3B31 !important;
    border-color: #CCC;
}
.btn-tab:hover{
    background-color: #F2CC8C !important;
    color: #333 !important;
}
footer{
    clear:both;
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.bread-separator{
    color: #CCC;
}

.label-number{
    background-color: #593b30;
    color: white;
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover{
    color: #fff;
}
.embed-area {
    width: 100%;
    padding: 5px;
}
.tab-container{
    margin-bottom: 15px;
}
.ayh_numbers{
    white-space: nowrap;
}
.tag-list a{
    padding: 3px;
    display: inline-block;
}
.surah-jumper{
    min-width: 100%;
    max-width: 50%;
}

.media:first-child {
    margin-top: 15px;
}

.ads-sidebar-right {
    padding-left: 30px;
}
.ads-sidebar-left {
    padding-left: 0px;
}

.ads_home {
    margin-top: 20px;
    margin-bottom: 40px;
}
.adsfooter{
    margin: 40px 0px;
}


@media screen and (max-width: 480px) {
    .surah-jumper {
        min-width: 100%;
        max-width: 50%;
    }
    .center-block{
        /*float: none !important;*/
        padding-left: 30%;
    }
}
